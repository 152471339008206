@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: #222222;
    overflow-y: auto;
    position: relative;
    font-family: 'Montserrat', sans-serif; 
    min-height: 100%;
    z-index: 0;
}

#particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#particles-js canvas {
    pointer-events: auto;
}


.navbar {
    width: 100%;
    background-color: #333333;
    color: #ffffff;
    padding: 20px 0;
    position: relative; 
    top: 0;
    left: 0;
    z-index: 1; 
    display: flex;
    justify-content: center;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0px;
    width: 900px;
    max-width: 100%;
}

.navbar a {
    text-decoration: none;
    color: #dadce0ab;
    padding: 20px 30px;
    transition: background-color 0.3s;
}


.navbar a:hover,
.navbar a.active {
    color: #ffffff;
}



.background-container {
    height: 380px;
    background: center center;
    background-size: cover;
    filter: brightness(0.8) saturate(1.2);
    margin: 0 auto;
    margin-bottom: 10px;
    transition: width 0.5s ease, transform 0.5s ease, opacity 0.5s ease; 
}

.background-container.home {
    width: 100%;
}

.background-container.other {
    width: 945px; 
    pointer-events: none; 
}

.background-container.contact-page {
    transform: translateY(-100%); 
    opacity: 0; 
    pointer-events: none;
}


.cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px;
    padding-top: 35px;
}


.card {
    width: 500px;
    height: 300px;
    background-color: #666666;
    border-radius: 20px;
    transition: transform 0.3s ease;
    filter: blur(1.5px) brightness(0.8);
    transition: transform 0.5s ease, filter 0.5s ease;
}

.card:hover {
    transform: scale(1.1);
    filter: blur(0) brightness(1);
}


.background-container {
    height: 380px;
    background: center center;
    background-size: cover;
    filter: brightness(0.8) saturate(1.2);
    margin: 0 auto;
    margin-bottom: 10px;
    transition: width 0.5s ease, transform 0.5s ease, opacity 0.5s ease; 
}


.cards-container .services-text {
    color: #734c7a;
    text-align: center;
    padding: 40px;
}

.cards-container .services-text h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.cards-container .services-text p {
    font-size: 18px;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.card-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(97, 95, 95, 0.158);
    color: white;
    opacity: 0; 
    transition: opacity 0.3s ease;
    text-align: center;
    font-size: 16px;
    font-family: 'Press Start 2P', system-ui;
}

.card:hover .card-text {
    opacity: 1; 
}


.container .services-details {
    color: #ffffff;
    text-align: justify;
}

.container .services-details h2 {
    font-size: 28px;
    margin-bottom: 10px;
}

.container .services-details p {
    font-size: 18px;
    margin-bottom: 20px;
}



.container {
    width: 900px; 
    background-color: #1c1c1c; 
    overflow: hidden;
    padding: 20px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    z-index: 1;
    overflow-y: auto;
    min-height: 80vh;
    margin: 0 auto 0 auto; 
    margin-top: -30px;
    flex: 1;
    transition: margin-top 0.5s ease;
}


.container::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 70%;
    background: radial-gradient(
        circle at var(--bg-effect-x, 50%) var(--bg-effect-y, 50%),
        var(--bg-effect-color, transparent) 0%,
        transparent 40%
    );
    opacity: 0;
    transition: opacity 0.5s ease-out;
    pointer-events: none;
    z-index: 0; 
}

.container.bg-effect-active::before {
    opacity: 1;
}

.container > div {
    position: relative;
    z-index: 1; 
}

.contact-page.container {
    margin-top: -400px;
}

@media (max-width: 768px) {
    .container {
        width: 100vw;
        border-radius: 0%;
    }
}

.footer-bar {
    width: 100vw;
    height: 120px;
    background-color: #111111;
    color: #ffffff;
    text-align: center;
    line-height: 80px; 
    position: absolute;
    z-index: 1;
    margin-top: 0px;
    padding: 0;
}


.react-logo {
    height: 1.5em; 
    vertical-align: middle; 
    margin-left: 7px; 
}


:root {
    --sprite-scale: 0.8; 
}


.sprite {
    position: fixed; 
    left: 30px; 
    bottom: 0px;
    width: 500px; 
    height: 500px; 
    background-repeat: no-repeat;
    animation: caminar 0.7s steps(7) infinite; 
    transform: scaleX(-1) scale(var(--sprite-scale)); 
    transform-origin: center; 
    z-index: 2;
}

@keyframes caminar {
    100% {
        background-position: -3500px 0; 
    }
}


.side-menu {
    position: fixed;
    right: 17.5%; 
    bottom: 7%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    z-index: 2;
}


.main-icon {
    width: 60px; 
    cursor: pointer;
    filter: brightness(0.7); 
    z-index: 2
}


.menu-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    position: relative;
}


.icon {
    width: 50px; 
    cursor: pointer;
    filter: invert(80%) brightness(90%); 
    opacity: 0;
    transform: translateY(100px); 
    transition: opacity 0.5s, transform 0.5s;
}


.side-menu:hover .icon {
    animation: subirIcono 0.5s forwards;
}


.side-menu:hover .icon:nth-child(1) {
    animation-delay: 0.5s;
}

.side-menu:hover .icon:nth-child(2) {
    animation-delay: 0.4s;
}

.side-menu:hover .icon:nth-child(3) {
    animation-delay: 0.3s;
}

.side-menu:hover .icon:nth-child(4) {
    animation-delay: 0.2s;
}

.side-menu:hover .icon:nth-child(5) {
    animation-delay: 0.1s;
}


@keyframes subirIcono {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bajarIcono {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(100px);
    }
}


.side-menu .icon {
    animation: bajarIcono 0.5s forwards;
}


.side-menu:hover .icon {
    animation: subirIcono 0.5s forwards;
}


.side-menu .main-icon:hover {
    filter: brightness(1.5);
}


.side-menu .icon:hover {
    filter: invert(100%);
}



.inicio-container {
    text-align: justify;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    line-height: 1.5;
    
}


.welcome-text h1 {
    font-size: 32px;
    color: #ffffff;
    margin-bottom: 20px;
}

.welcome-text p {
    font-size: 18px;
    color: #dadce0;
    margin: 10px 0;
}

.welcome-text h2 {
    font-size: 24px;
    color: #ffffff;
    margin-top: 50px;
    font-weight: normal;
}


.spacing {
    height: 10px;
}


.icon-section {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    padding: 20px 0;
}

.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.icon-item .icon-name {
    font-family: 'Press Start 2P', system-ui;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 40px;
}

.icon-item img {
    width: 90px;
    height: 90px;
    transition: transform 0.5s ease;
    transform-origin: center;
    margin-left: 0px;
    margin-right: 0px;
}

.icon-item img:hover {
    transform: scale(1.3) rotate(5deg);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
}

.icon-item:hover .icon-name {
    transform: translateY(-10px);
    color: #d9b8d3;
    transition: transform 0.3s ease, color 0.3s ease;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}




.background-container.servicios-page {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    
    
}



.servicios-intro {
    display: flex;                   
    flex-direction: column;          
    justify-content: center;         
    align-items: flex-start;         
    margin: 0 40px;
    height: 100%;                    
}


.servicios-intro h1 {
    font-size: 36px;
    color: #734c7a;
    margin-bottom: 30px;
    font-family: 'Press Start 2P', system-ui;
}

.servicios-intro p {
    font-size: 18px;
    color: #734c7a; 
    margin: 10px 0;
    line-height: 1.6;
    font-family: 'Press Start 2P', system-ui;
}


.background-container.other {
    width: 945px;
    pointer-events: auto; 
}


.servicios-content {
    margin: 50px;
}

.servicios-content p {
    font-size: 18px;
    color: #dadce0; 
    margin: 20px 0;
    text-align: justify;
    line-height: 1.6;
}

.servicios-content h2 {
    font-size: 24px;
    color: #ffffff;
    margin-top: 50px;
    font-weight: normal;
    text-align: justify;
}






.proyectos-intro {
    display: flex;                   
    flex-direction: column;          
    justify-content: center;         
    align-items: flex-start;         
    margin: 0 40px;
    height: 100%;  
}

.proyectos-intro h1 {
    font-size: 36px;
    color: #734c7a;
    margin-bottom: 30px;
    font-family: 'Press Start 2P', system-ui;
}

.proyectos-intro p {
    font-size: 18px;
    color: #734c7a; 
    margin: 10px 0;
    line-height: 1.6;
    font-family: 'Press Start 2P', system-ui;
}



.servicios-content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}


.servicios-content h2:nth-of-type(2) {
    margin-top: 200px;
}




.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 50px auto;
}

.contact-form label {
    
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
    width: 95%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 25px;
    border: none;
    background-color: #ffffff;
    font-size: 16px;
    color: #000000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.contact-form textarea {
    resize: none; 
}

.contact-form button {
    --color: #fff;
    --color-hover: #000;
    --background: #b69cbd;
    --background-hover: #cd9adb;
    --hover-back: #efb6ff;
    --hover-front: #ffd9fd;

    width: 200px;
    align-self: flex-end;
    padding: 15px;
    border-radius: 25px;
    border: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    font-size: 18px;

    background: var(--background);
    color: var(--color);
    transition: color .3s linear, background .3s linear;
    
    z-index: 1; 
}

.contact-form button div {
    position: relative;
    z-index: 2; 
}

.contact-form button:before,
.contact-form button:after {
    content: '';
    position: absolute;
    background: var(--hover-back);
    top: 0;
    left: 0;
    right: 0;
    height: 200%;
    z-index: 0; 
    border-radius: 40%;
    transform: translateY(50%);
    transition: transform 0.4s ease-in, border-radius 0.5s ease;
    mix-blend-mode: #fff; 
}

.contact-form button:after {
    background: var(--hover-front);
    transition-delay: 0.08s;
}

.contact-form button.in {
    --color: var(--color-hover);
    --background: var(--background-hover);

    background: var(--background);
    color: var(--color);

    &:before,
    &:after {
        transform: translateY(0);
        border-radius: 5%;
    }

    &:after {
        border-radius: 10%;
    }
}

.contact-form button.out {
    &:before {
        transition-delay: 0.06s;
    }
}

